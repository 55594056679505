.About-header {
  padding-bottom: calc(3rem + 5.128vw);
}

.about-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.download-button {
  background: var(--secondary-color);
  color: var(--primary-color);
  align-self: center;
}

.sec_sp_about {
  color: var(--text-color);
  background: var(--primary-color);
}

.sec_sp_about td {
  min-width: 75%;
  background: var(--primary-color);
}

.sec_sp_about_dsc_td {
  background: var(--primary-color);
}

.table td,
.table th {
  color: var(--text-color);
  background: var(--primary-color);
}

.t_border {
  border: var(--text-color) !important;
}

.tr_worktimeline {
  display: flex;
  justify-content: space-between;
}

.tr_worktimeline_title {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.tr_worktimeline_title_italic {
  font-style: italic;
}

.tr_worktimeline_title_italic a {
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
}

.tr_worktimeline_title_bold {
  white-space: pre-wrap;
  font-weight: bold;
  margin-top: -1px;
}

.worktimeline_desc {
  padding: 8px 0;
}

.progress-title {
  font-size: 16px;
  font-style: italic;
  margin: 15px 0 20px;
  font-family: "Raleway";
}

.progress {
  height: 5px;
  background: var(--secondary);
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible;
}

.progress .progress-bar {
  position: relative;
  background: var(--text-color);
  animation: animate-positive 2s;
  overflow: visible;
  opacity: 0.9;
}

.progress .progress-value {
  position: absolute;
  top: -30px;
  right: 8px;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
  color: var(--text-color);
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

.section-title {
  font-size: 45px;
}

.service__title {
  padding: 8px 0;
  border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
  padding-bottom: 4px;
}

/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
