.lang_dropdown {
  background: var(--primary-color);
  width: 1.5em;
  height: 2.5em;
  border: unset;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes appear {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.lang_dropdown ul {
  display: none;
  padding: 0.3em;
  z-index: 999999999;
  background-color: var(--primary-color);
  border-radius: 0.2em;

  animation: appear 200ms ease-in;
}

.lang_dropdown li {
  width: 2.5em;
  height: 2.5em;
  padding: 5px 0.5em;
  align-items: center;
}

.lang_dropdown img {
  width: 1.5em;
  height: 1.5em;
  user-select: none;
}
