@keyframes appear {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
}

.overlay {
  display: none; /* Hidden by default */
  position:fixed;
  width: 100%;
  height: 100vh;
  top: 5vh;


  align-items: center;
  justify-content: center;
  z-index: 111111;

  animation: appear 200ms ease-in;
}

.modal-bg {
  width: 100%;
  top: -50%;
  height: 200%;
  opacity: 0.9;

  background-color: var(--primary-color);
  position: absolute;
  overscroll-behavior: contain;
}

.modal {
  background: var(--bg-color);
  border: solid 6px var(--text-color);

  overflow-x: auto;
  overflow-y: auto;

  padding: 1em;
  position: relative;
  display: flex;
  width: 80%;
  max-height: 75%;

  margin-bottom: 5%;
  height: auto;
}

.text-content {
  padding-left: 4em;
  padding-right: 4em;
  max-width: 70%;
}

.text-content h3 {
  padding-top: 1em;
  font-weight: bold;
  text-decoration-line: none;
}

.modal img {
  user-select: none;
  max-width: 350px;
  max-height: 350px;
  min-width: auto;
  min-height: auto;
  border: solid 4px var(--text-color);
}

.text-content img {
  height: 4em;
  max-width: none;
  max-height: none;
  border: none;
}

.text-content ul {
  display: flex;
  padding: 0;
  width: auto;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;

  padding-left: 0.2em;
  padding-right: 0.2em;
  column-gap: 1em;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 30px;
  font-weight: bold;
  color: var(--secondary-color);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .overlay {
    top: 17vh;
  }

  .modal {
    top: -5em;
    margin-bottom: unset;
    width: 90%;
    padding: 0.5em;
    left: -1.1em;
    max-height: 80%;
  }

  .modal img {
    display: none;
  }

  .text-content {
    padding-left: 1em;
    padding-right: 1em;
    max-width: unset;
    max-height: unset;
  }

  .text-content h3 {
    padding-top: 1em;
    font-size: 16px;
    font-weight: bold;
  }

  .text-content h1 {
    font-size: 1em;
    font-weight: bold;
    padding-right: 25px;
  }

  .text-content h4 {
    font-size: 1em;
  }

  .text-content img {
    display: block;
    height: 2.5em;
  }

  .closeButton {
    top: 15px;
    right: 10px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1600px) {
  .text-content h3 {
    padding-top: 1em;
    font-size: 20px;
  }

  .text-content h1 {
    font-size: 2em;
  }

  .text-content h4 {
    font-size: 1.2em;
  }

  .text-content img {
    display: block;
    height: 3em;
  }

  .closeButton {
    top: 20px;
    right: 20px;
    font-size: 20px;
  }
}
