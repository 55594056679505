.po_items_ho {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  column-width: 15em;
  -moz-column-width: 15em;
  -webkit-column-width: 15em;
  column-gap: 4rem;
  -moz-column-gap: 4rem;
  -webkit-column-gap: 4rem;
}

.po_item {
  display: inline-block;
  width: 29%;
  height: 29%;

  text-align: center;
  margin: 1rem auto;
  position: relative;
  background: var(--secondary-color);
  padding: 6px;
  border: 1px solid var(--secondary-color);

  font-size: 0;
}

.po_item img {
  width: 300;
  height: auto;
  max-width: 100%;
}

.po_item .content {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--overlay-color);
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  border: 1px solid black;
}

.po_item .content {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 20px;
}

.po_item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  opacity: 1;
}

.po_item:hover .content {
  opacity: 1;
  color: var(--secondary-color);
  cursor: pointer;
}

.po_item .content button {
  background: var(--bg-color);
  border: solid 1px var(--text-color);
  padding: 4px 8px;
  text-align: center;
  font-size: 1rem;
}

.po_item .content a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1600px) {
  .po_items_ho {
    column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .po_items_ho {
    column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
  }

  .po_item {
    width: 40%;
    height: 40%;

    padding: 4px;

    margin: 0.3rem auto;
    border: 1px solid var(--secondary-color);
  }

  .po_item .content {
    font-size: 16px;
  }

  .po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
  }
}

@media only screen and (max-width: 700px) {
  .po_items_ho {
    column-gap: 0.5rem;
    -moz-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
  }

  .po_item .content {
    font-size: 12px;
  }

  .po_item:hover .content {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
  }
}
